.rdg-cell {
	display: inline-block;
	position: absolute;
	height: inherit;
	padding: 0 0px;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background-color: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-indent: 10px;
}
.rdg-cell-frozen {
	position: -webkit-sticky;
	position: sticky;
	z-index: 2
}
.rdg-cell-frozen-last {
	box-shadow: 2px 0 5px -2px rgba(136, 136, 136, .3)
}
.react-contextmenu--visible {
	z-index: 1000
}
.rdg-cell.readonly {
	background-color: #000
}
.rdg-cell .form-control-feedback {
	color: #a94442;
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 1000000;
	display: block;
	width: 34px;
	height: 34px
}
.rdg-cell.editing {
	padding: 0;
	overflow: visible!important
}
.rdg-cell.editing .has-error input {
	border: 2px solid red!important;
	border-radius: 2px!important
}
.rdg-cell-value ul {
	margin-top: 0;
	margin-bottom: 0;
	display: inline-block
}
.rdg-cell-value .btn-sm {
	padding: 0
}
.rdg-cell-expand {
	float: right;
	display: table;
	height: 100%
}
.rdg-cell-expand>span {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer
}
.rdg-child-row-action-cross:before,
rdg-child-row-action-cross:after {
	content: "";
	position: absolute;
	background: grey;
	height: 50%
}
.rdg-child-row-action-cross:before {
	left: 21px;
	width: 1px;
	height: 100%
}
.rdg-child-row-action-cross:after {
	top: 50%;
	left: 20px;
	height: 1px;
	width: 15px;
	content: "";
	position: absolute;
	background: grey
}
.rdg-child-row-action-cross:hover {
	background: red
}
.rdg-child-row-btn {
	cursor: pointer;
	position: absolute;
	top: calc(50% - 6px);
	height: 12px;
	width: 12px;
	z-index: 2;
	background: #fff
}
.rdg-child-row-btn .MuiSvgIcon-root {
	font-size: 16px;
	color: grey;
	position: absolute;
	top: calc(50% - 8px);
	left: calc(50% - 8px)
}
.rdg-child-row-btn .MuiSvgIcon-root:hover {
	color: red
}
.rdg-cell-action {
	float: right;
	height: 100%;
	text-indent: 0px;
	width: 27px;
	left: -5%;
	position: relative;
}
.rdg-cell-action-last {
	margin-right: -8px
}
.rdg-cell-action-button {
	width: 35px;
	height: 100%;
	text-align: center;
	position: relative;
	display: table;
	color: #4a9de2
}
.rdg-cell-action-button>span {
	display: table-cell;
	vertical-align: middle
}
.rdg-cell-action-button-toggled,
.rdg-cell-action-button:hover {
	color: #447bbb
}
.rdg-cell-action-menu {
	position: absolute;
	top: 100%;
	z-index: 1000;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	box-shadow: 0 0 3px 0 #ccc
}
.rdg-cell-action-menu>span {
	display: block;
	padding: 3px 10px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap
}
.rdg-cell-action-menu>span:hover {
	color: #262626;
	text-decoration: none;
	background-color: #f5f5f5
}
.rdg-cell-mask {
	position: absolute;
	pointer-events: none;
	outline: 0
}
.rdg-filter-container {
	display: flex;
	height: inherit;
	align-items: center
}
.rdg-filter {
	width: 100%;
	padding: 4px;
	font-size: 14px
}
.rdg-filter-badge {
	cursor: help;
	padding-left: 4px
}
.rdg-checkbox-label {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}
.rdg-checkbox-input {
	all: unset;
	width: 0;
	margin: 0
}
.rdg-checkbox {
	content: '';
	width: 20px;
	height: 20px;
	border: 2px solid #ddd;
	background: #fff
}
.rdg-checkbox-input:checked+.rdg-checkbox {
	background: #005295;
	box-shadow: inset 0 0 0 4px #fff
}
.rdg-checkbox-input:focus+.rdg-checkbox {
	border-color: #62b8ff
}
.rdg-root {
	position: relative;
	z-index: 0;
	border: 1px solid #ddd;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 14px
}
.rdg-root *,
.rdg-root::after,
.rdg-root::before {
	box-sizing: inherit
}
.rdg-viewport {
	position: relative;
	overflow-x: auto;
	overflow-y: scroll;
	background-color: #fff;
	will-change: transform
}
.rdg-grid {
	min-height: 1px
}
.rdg-editor-container {
	position: absolute
}
.rdg-select-editor,
.rdg-text-editor {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	box-sizing: border-box;
	width: calc(100% + 1px);
	height: calc(100% + 1px);
	padding: 1px 7px 0;
	margin: -1px 0 0 -1px;
	border: 2px solid #ccc;
	background-color: #fff;
	font-size: 14px;
	line-height: 1.2
}
.rdg-select-editor::placeholder,
.rdg-text-editor::placeholder {
	color: #999;
	opacity: 1
}
.rdg-select-editor:-ms-input-placeholder,
.rdg-text-editor:-ms-input-placeholder {
	color: #999
}
.rdg-select-editor::-ms-input-placeholder,
.rdg-text-editor::-ms-input-placeholder {
	color: #999
}
.rdg-select-editor:focus,
.rdg-text-editor:focus {
	border-color: #66afe9
}
.rdg-header {
	background: #f9f9f9;
	overflow: hidden;
	font-weight: 700
}
.rdg-header-row {
	position: relative;
	background-color: inherit;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.rdg-header-cell-resizer {
	cursor: col-resize;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 10px
}
.rdg-cell .Select {
	max-height: 30px;
	font-size: 12px;
	font-weight: 400
}
.rdg-cell .Select-control {
	max-height: 30px;
	border: 1px solid #ccc;
	color: #555;
	border-radius: 3px
}
.rdg-cell .is-focused:not(.is-open)>.Select-control {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}
.rdg-cell .Select-control .Select-placeholder {
	line-height: 20px;
	color: #999;
	padding: 4px
}
.rdg-cell .Select-control .Select-input {
	max-height: 28px;
	padding: 4px;
	margin-left: 0
}
.rdg-cell .Select-control .Select-input input {
	padding: 0;
	height: 100%
}
.rdg-cell .Select-control .Select-arrow-zone .Select-arrow {
	border-color: gray transparent transparent;
	border-width: 4px 4px 2.5px
}
.rdg-cell .Select-control .Select-value {
	padding: 4px;
	line-height: 20px!important
}
.rdg-cell .Select--multi .Select-control .Select-value {
	padding: 0;
	line-height: 16px!important;
	max-height: 20px
}
.rdg-cell .Select--multi .Select-control .Select-value .Select-value-icon,
.rdg-cell .Select--multi .Select-control .Select-value .Select-value-label {
	max-height: 20px
}
.rdg-cell .Select-control .Select-value .Select-value-label {
	color: #555!important
}
.rdg-cell .Select-menu-outer {
	z-index: 2
}
.rdg-cell .Select-menu-outer .Select-option {
	padding: 4px;
	line-height: 20px
}
.rdg-cell .Select-menu-outer .Select-menu .Select-option.is-focused,
.rdg-cell .Select-menu-outer .Select-menu .Select-option.is-selected {
	color: #555
}
.rdg-header-sort-cell {
	cursor: pointer;
	display: flex
}
.rdg-header-sort-name {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis
}
.rdg-selected {
	border: 2px solid #66afe9
}
.rdg-selected .drag-handle {
	pointer-events: auto;
	position: absolute;
	bottom: -5px;
	right: -4px;
	background: #66afe9;
	width: 8px;
	height: 8px;
	border: 1px solid #fff;
	border-right: 0;
	border-bottom: 0;
	cursor: crosshair;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab
}
.rdg-selected:hover .drag-handle {
	bottom: -8px;
	right: -7px;
	background: #fff;
	width: 16px;
	height: 16px;
	border: 1px solid #66afe9
}
.rdg-selected-range {
	border: 1px solid #66afe9;
	background-color: #66afe930
}
.react-grid-cell-dragged-over-down,
.react-grid-cell-dragged-over-up {
	border: 1px dashed #000;
	background: rgba(0, 0, 255, .2)!important
}
.react-grid-cell-dragged-over-up {
	border-bottom-width: 0
}
.react-grid-cell-dragged-over-down {
	border-top-width: 0
}
.react-grid-cell-copied {
	background: rgba(0, 0, 255, .2)!important
}
.rdg-row {
	background-color: #fff
}
.rdg-row:hover {
	background-color: #f5f5f5
}
.rdg-row-selected {
	background-color: #dbecfa
}
.rdg-row-selected:hover {
	background-color: #c9e3f8
}
.rdg-row-expand-icon {
	cursor: pointer
}
.rdg-row-expand-icon:hover {
	color: #777
}
.rdg-row-group {
	padding: 0 8px;
	border-bottom: 1px solid #ddd
}
.rdg-summary {
	border-top: 2px solid #aaa;
	position: relative;
	overflow: hidden
}
.rdg-summary .rdg-row:last-child .rdg-cell {
	border-bottom-color: transparent
}