.App {
  scrollbar-width: none;
}

#login_riquadro {
  width: 350px;
  height: 450px;
  margin: auto;
  margin-top: 2%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

.login_class{
  width: 240%;
  margin-left: 13%
}

#sceltaprogetto_riquadro {
  margin-left: 45%;
  width: 350px;
  height: 450px;
  margin: auto;
  margin-top: 2%;
}

#Logo {
  margin-top: 10%;
  width: 70%;
}

.Spazio {
  height: 10px;
}


#bottone_login {
  margin-top: 9%;
  width: 100px;
  color: white;
  background-color: #ea515b;
}

.porcata {
  width: 240%;
  margin-left: 13%
}

#Logo_2 {
  width: 50%;
  margin: auto;
  margin-top: 5%
}

#Titolo {
  margin-top: 2%;
}

#menu {
  margin-left: 35px;
  margin-top: 30%;
}

#tendina {
  margin-top: 10%;
  margin-left: 20%;
  width: 200px;
}

#icona {
  color: #ea515b;
  margin-left: 15%;
}

#color_icon{
  color: #ea515b;
}

.prog_attivi {
  margin-top: 1%;
}

#radio {
  margin-left: 10%;
}

#lista_prog {
  border-color: #ea515b;
}

#mese {
  border-color: #ea515b;
  width: 50%;
  display: inline-block;
}

#anno {
  width: 50%;
  border-color: #ea515b;
  display: inline-block;
}

#ok {
  background-color: #ea515b;
  color: white;
}

#tabella {
  width: 90%;
  margin-top: 5%;
}

.input {
  width: 30%;
}

.nome_progetto {
  width: 16.1%;

}

.costi {
  width: 17%;
}

.trasferta {
  width: 10%;
}

.giorni {
  width: 54%;
}

#piano_economico {
  padding-top: 7px;
  padding-bottom: 10px;
  width: 70%;
  padding-left: 15px;
}

.fine {
  background-color: blueviolet;
}

#link_menu {
  font-size: 18px;
  margin-bottom: 5px;
}

#error_msg_login {
  margin-top: 7%;
  width: 80%;
  height: 40%
}

#sottomenu {
  margin-left: 5px;
  font-size: 23px;
  color: #001722;
}

#dropdown_emp {
  margin-left: 60px;
  margin-top: 35px;
  font-size: 21px;
  color: #f65058;
}

#piano_contabile {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
#div_spinnger{
  padding-top: 20%;
}
#spinner_color_lobra{
  color: #f65058; 
}

#bottone_addemp{
  position: absolute;
  left: 285px;

}

/* ::-webkit-scrollbar {
  display: none
}
 */
 
#lista_emp {
  border-color: #ea515b;
  width: 260px;
  position: absolute;
}

#start_date_prj {
  border-color: #ea515b;
  width: 200px;
  left: 40%;
  position: absolute;
}

#end_date_prj {
  border-color: #ea515b;
  width: 200px;
  left: 56%;
  position: absolute;
}

#start_date_prj_label {
  left: 44%;
  position: absolute;
  z-index: 15;
  top: 8%;
}

#end_date_prj_label {
  width: 200px;
  left: 60%;
  position: absolute;
  z-index: 15;
  top: 6%;
}

#size_start_date, #size_end_date{
  width: 100%
}

#santa_pazienza_errore{
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

#header_piano_economico, #header_piano_contabile{
background-color: #fde4e3;
color: #ea515b;
/* border: 1px solid #ea515b; */
}

#santa_pazienza_errore{
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

#footer_piano_contabile{
  background-color: #e6e7e8;
  font-weight: bold;
}

#start_date{
position: absolute;
padding-top: 10%;
}

#end_date{
  position: absolute;
padding-top: 10%;

}

#arrow_left_icon{
  float: left;
}

#arrow_right_icon{
  float: right;
}

#div_schedulazione{
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px
}

.textbox{
 border: transparent;
}

#dimens_popup{
  width: 520px;
}

#dimens_seach{
  max-width: 520px;
}

#td_transparent{
  border-color: transparent;
}

#email{
  margin-top:30%;
   width: 1200px;
  margin-bottom: 10%;
  border-color: #ea515b;
}
 
#password{
  border-color: #ea515b;
}

.emp_rosso{
  background-color: #ffa39e7a;
  position: absolute;
  top: 0.7%;
  left: 1%;
  width: 98%;
  height: 98.7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp_bianco{
  background-color: white;
  position: absolute;
  top: 0.7%;
  left: 1%;
  width: 98%;
  height: 98.7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp_verde{
  background-color: #b7eb8f;;
  position: absolute;
  top: 0.7%;
  left: 1%;
  width: 98%;
  height: 98.7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp_giallo{
  background-color: #f7e47e;
  position: absolute;
  top: 0.7%;
  left: 1%;
  width: 98%;
  height: 98.7%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.editable-cell-value-wrap{
  width: 100%;
  height: 100%;
}

#color_datagrid  div.rdg-cell div.react-grid-Cell {
  padding-left: 0px ;
  padding-right: 0px;
  line-height: 2.1;
  text-align: center;
}

.inlineb{
  display: inline-block;
}

#last_update{
  display: inline-flex;
}

#ultimo_agg{
  font-weight: bold;
  margin-left: 3px;
  padding-right: 6px;
  padding-bottom: 10px;
  font-size: 12px;
}

#user_ultimo_agg{
  padding-right: 6px;
  font-size: 12px;
}


#colore_ultima_riga{
  background-color: rgb(238, 238, 238);
  font-weight: bold;
  text-align: right;
  padding-right: 8px;
}

#allineamento_numeri_destra_chiaro{
  background: rgb(238, 238, 238) none repeat scroll 0% 0%;
  text-align: right;
  padding-right: 8px;
}

#allineamento_numeri_destra_scuro{
  background: rgba(206, 205, 205, 0.76) none repeat scroll 0% 0%;
  text-align: right;
  padding-right: 8px;
}

